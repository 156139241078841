let key = {};
if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config");
  const API_URL = "https://productionapi.matrixwallet.io";

  key = {
    API_URL: `${API_URL}`,
    FRONT_URL: "https://matrixwallet.io",
    ADMIN_URL: "https://controls.matrixwallet.io",
    getGeoInfo: "https://ipapi.co/json/",
  };
} else if (process.env.REACT_APP_MODE === "demo") {
  console.log("Set demo Config");
  const API_URL = "https://matrix-walletapi.wearedev.team";

  key = {
    API_URL: `${API_URL}`,
    FRONT_URL: "https://matrix-wallet-frontend-2024.pages.dev",
    ADMIN_URL: "https://matrix-wallet-controls-2024.pages.dev",
    getGeoInfo: "https://ipapi.co/json/",
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    API_URL: `${API_URL}:2053`,
    getGeoInfo: "https://ipapi.co/json/",
  };
}

export default key;
